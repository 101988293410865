import { useContext } from "react";
import { useOrganization } from "../contexts/OrganizationControlledContext";
import { UserContext } from "../contexts/UserContext";
import { isPersonalOrganization } from "../models";

export const useIsPersonalUser = () => {
  const org = useOrganization();
  const { userState } = useContext(UserContext);
  if (!org) {
    return true;
  }
  if (!userState.user) {
    return true;
  }
  return isPersonalOrganization(org, userState.user.uid);
};

export const useStrictIsPersonalUser = () => {
  const org = useOrganization();
  const { userState } = useContext(UserContext);
  if (!org) {
    return undefined;
  }
  if (!userState.user) {
    return undefined;
  }
  return isPersonalOrganization(org, userState.user.uid);
};
